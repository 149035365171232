.psp-cascading .header {
  width: 100%;
  background-color: #F9F9F9;
  height: 70px;
  margin-top: 60px;
}

.psp-cascading .input-column {
  width: 200px;
  min-width: 130px;
  margin: 10px;
}

.psp-cascading .proximity {
  height: 12px;
  background: rgb(19, 231, 19);
  background: -webkit-linear-gradient(left, rgba(19, 231, 19, 1) 0%, rgba(247, 252, 16, 1) 50%, rgba(228, 5, 5, 1) 100%);
  background: -o-linear-gradient(left, rgba(19, 231, 19, 1) 0%, rgba(247, 252, 16, 1) 50%, rgba(228, 5, 5, 1) 100%);
  background: linear-gradient(to right, rgba(19, 231, 19, 1) 0%, rgba(247, 252, 16, 1) 50%, rgba(228, 5, 5, 1) 100%);
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 3px;
}

.psp-cascading .proximity .proximity-remain {
  float: right;
  height: 100%;
  background: #4E5B6C;
  border-radius: 0 3px 3px 0;
}

.psp-cascading #myProgress {
  width: 100%;
  height: 12px;
  margin-top: 5px;
  border-radius: 5px;
  background: rgb(19, 231, 19);
  background: -webkit-linear-gradient(left, rgba(19, 231, 19, 1) 0%, rgba(247, 252, 16, 1) 50%, rgba(228, 5, 5, 1) 100%);
  background: -o-linear-gradient(left, rgba(19, 231, 19, 1) 0%, rgba(247, 252, 16, 1) 50%, rgba(228, 5, 5, 1) 100%);
  background: linear-gradient(to right, rgba(19, 231, 19, 1) 0%, rgba(247, 252, 16, 1) 50%, rgba(228, 5, 5, 1) 100%);
}

.psp-cascading #myBar {
  width: 100%;
  float: right;
  height: 12px;
  border-radius: 5px;
  background: rgb(95, 97, 95);
  background: -webkit-linear-gradient(left, rgba(95, 97, 95, 1) 9%, rgba(235, 238, 241, 1) 37%, rgba(95, 97, 95, 1) 65%);
  background: -o-linear-gradient(left, rgba(95, 97, 95, 1) 9%, rgba(235, 238, 241, 1) 37%, rgba(95, 97, 95, 1) 65%);
  background: linear-gradient(to right, rgba(95, 97, 95, 1) 9%, rgba(235, 238, 241, 1) 37%, rgba(95, 97, 95, 1) 65%);
}

.psp-cascading #myBar.noRadius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.psp-cascading .balance-container {
  color: #FFFFFF;
  border-radius: 4px;
}

.psp-cascading .allSides {
  box-shadow: 0 0 50px  rgba(46, 204, 113, 1);
  -moz-box-shadow: 0 0 20px  rgba(46, 204, 113, 1);
  -webkit-box-shadow: 0 0 20px  rgba(46, 204, 113, 1);
  -o-box-shadow: 0 0 20px  rgba(46, 204, 113, 1);
}

.psp-cascading .error-field {
  border: 2px solid #FF0000;
  border-radius: 7px;
}

.psp-cascading .psp-radio:checked,
.psp-radio:not(:checked) {
  position: absolute;
  left: -9999px;
}

.psp-cascading .psp-radio:checked + label,
.psp-radio:not(:checked) + label {
  position: relative;
  padding-left: 40px;
  padding-top: 5px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666666;
  font-size: 16px;
}

.psp-cascading .psp-radio:checked + label::before,
.psp-radio:not(:checked) + label::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 36px;
  height: 36px;
  border: 1px solid #CCCCCC;
  border-radius: 100%;
  background: #FFFFFF;
}

.psp-cascading .psp-radio:checked + label::after,
.psp-radio:not(:checked) + label::after {
  content: '';
  width: 22px;
  height: 22px;
  background: #25282A;
  position: absolute;
  top: 7px;
  left: 7px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.psp-cascading .psp-radio:not(:checked) + label::after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.psp-cascading .psp-radio:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.psp-cascading .icon-div {
  border: 1px solid #CCCCCC;
  height: 60px;
  border-radius: 5px;
}

.psp-cascading .icon-div img {
  height: 35px;
  margin: 10px;
}

.psp-cascading .mid-priority-wrapper {
  width: 100%;
  overflow: auto;
}

.psp-cascading .table-column table > tbody > tr > td:last-child,
.psp-cascading .table-column table > thead > tr > th:last-child {
  text-align: end;
}

.psp-cascading .panel-content {
  overflow: auto;
}

.psp-cascading .panel-content tbody td {
  vertical-align: middle;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.psp-cascading .panel-content tbody tr {
  margin-top: 5px;
}

.psp-cascading .panel-content tbody td:nth-child(2) {
  border-left: 1px solid #CCCCCC;
  border-radius: 5px 0 0 5px;
}

.psp-cascading .panel-content tbody td:last-child {
  border-right: 1px solid #CCCCCC;
  border-radius: 0 5px 5px 0;
}

.psp-cascading .panel-content tbody td:first-child {
  border: 0;
  padding: 0 8px 0 0;
}

.psp-cascading table {
  margin-top: 10px;
  border-collapse: separate;
  border-spacing: 0 15px;
  table-layout: fixed;
}

.psp-cascading table thead tr {
  background-color: #D1D1D1;
}

.psp-cascading .td-priority {
  width: 100px;
}

.psp-cascading .mid-warning {
  color: #25282A;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.tooltips {
  position: relative;
  display: inline;
}

.tooltips span {
  position: absolute;
  width: 40px;
  color: #000000;
  background: #FFFFFF;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 6px;
  bottom: 20px;
  margin-left: -19px;
  z-index: 999;
  -webkit-box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.3);
}

.btn-success:hover {
  background-color: #5CB85C !important;
  border-color: #4CAE4C;
}

.btn-success.focus,
.btn-success:focus {
  background-color: #5CB85C !important;
  border-color: #4CAE4C;
}

.tooltips span::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid #FFFFFF;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

@media screen and (max-width: 750px) {
  .psp-cascading .mid-priority-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 20px);
  }

  .psp-cascading .mid-priority-wrapper table td,
  .psp-cascading .mid-priority-wrapper table th {
    width: 150px;
  }
}
