.apidata .header {
  width: 100%;
  background-color: #F9F9F9;
  height: 70px;
  margin-top: 60px;
}

.apidata .error-field {
  border: 2px solid #FF0000 !important;
  border-radius: 7px;
}

.apidata .error-field .Select-control {
  border-collapse: collapse;
}

.apidata .input-column {
  width: 15.15%;
  min-width: 180px;
  margin: 10px;
  position: relative;
}

.apidata .amount-form .input-column {
  width: 8%;
  min-width: 100px;
}

.apidata .input-column.checkbox-column {
  width: unset;
  min-width: 20px;
}

.apidata .input-column.checkbox-column label.container {
  padding-left: 10px;
  padding-right: 10px;
}

.apidata .input-column.checkbox-column input[type="checkbox"] {
  zoom: 2;
  margin: 10px 0 0;
  cursor: pointer;
}

.apidata .first-datepicker .rdtOpen .rdtPicker {
  left: 0;
}

.apidata .datetime-container .form-control,
.apidata .react-datepicker-wrapper .form-control {
  padding-left: 35px !important;
}

.apidata .revenue-boxes-wrap {
  width: calc((100% * 1/3) - 10px);
  min-width: 290px;
  margin: 5px;
}

.apidata .revenue-boxes-wrap .info {
  width: 66%;
}

.apidata .revenue-boxes-wrap .chart-div {
  width: 33%;
}

.apidata .revenueBoxes {
  width: 100%;
  min-height: 125px;
  max-height: 230px;
  transition: height 1s ease-in-out;
  padding-top: 10px;
  color: #FFFFFF;
  border-radius: 5px;
  background-image: linear-gradient(#25282A, #4B4D48);
}

.apidata .revenueBoxes .currency-numbers {
  padding: 0;
  margin: 0 0 0 15px;
}

.apidata .revenue-details {
  width: 100%;
  -webkit-box-shadow: 0 -5px 6px 0 #25282A;
  -moz-box-shadow: 0 -5px 6px 0 #25282A;
  box-shadow: 0 -5px 6px 0 #25282A;
  background-color: #25282A;
  margin-top: 10px;
}

.apidata .revenueBoxes .revenue-toggler {
  cursor: pointer;
}

.apidata .revenue-boxes-wrap .revenue-table {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.apidata .revenue-boxes-wrap table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  border-spacing: 10px 0;
  margin: 0;
}

.apidata .revenue-boxes-wrap table th {
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  border: 0;
  padding: 0 0 0 0;
}

.apidata .revenue-boxes-wrap table td {
  font-size: 17px;
  text-align: center;
}

.apidata .proximity {
  height: 7px;
  background: linear-gradient(90deg, red, yellow, green);
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 3px;
}

.apidata .proximity .proximity-remain {
  float: right;
  height: 100%;
  background: #4E5B6C;
  border-radius: 0 3px 3px 0;
}

.apidata #myProgress {
  width: 100%;
  height: 7px;
  margin-top: 5px;
  border-radius: 5px;
  background: linear-gradient(90deg, red, yellow, green);
}

.apidata #myBar {
  width: 100%;
  float: right;
  height: 7px;
  border-radius: 5px;
  background: #084F9D;
}

.apidata #myBar.noRadius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.apidata .balance-container {
  color: #FFFFFF;
  border-radius: 4px;
}

.apidata .panel-content tbody td {
  border: 0;
}

.apidata .table-column table > tbody > tr > td:last-child,
.apidata .table-column table > thead > tr > th:last-child {
  text-align: end;
}

.apidata .apidata .panel-content {
  overflow: auto;
}

.apidata .allSides {
  box-shadow: 0 0 50px  rgba(46, 204, 113, 1);
  -moz-box-shadow: 0 0 20px  rgba(46, 204, 113, 1);
  -webkit-box-shadow: 0 0 20px  rgba(46, 204, 113, 1);
  -o-box-shadow: 0 0 20px  rgba(46, 204, 113, 1);
}

.dashboard-user-item {
  color: #FFFFFF;
  width: 22%;
  height: 100%;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  background-image: linear-gradient(#25282A, #4B4D48);
}

.apidata .revenueBoxes .arrow {
  width: 20px;
  height: 20px;
  fill: #FFFFFF;
}

.apidata .revenueBoxes .arrow-up {
  transform: rotate(180deg);
}

.circle-chart__circle {
  animation: circle-chart-fill 2s reverse;
}

.circle-chart__circle--negative {
  transform: rotate(-90deg) scale(1, -1);
}

.circle-chart__info {
  animation: circle-chart-appear 2s forwards;
  opacity: 0;
  transform: translateY(0.3em);
}

@keyframes circle-chart-fill {
  to { stroke-dasharray: 0 534; }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.grid {
  display: grid;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  grid-template-columns: repeat(1, 1fr);
}

@media screen and (max-width: 650px) {
  .apidata .revenue-boxes-wrap {
    flex-grow: 1;
    max-width: calc(var(--vw, 1vw) * 100);
  }

  .apidata .mid-row {
    padding: 10px 0 20px;
    border-bottom: 1px solid #CCCCCC;
  }

  .apidata .amount-form .input-column:not(.checkbox-column) {
    flex-grow: 1 !important;
  }
}

@media screen and (min-width: 725px) and (max-width: 1110px) {
  .apidata .revenueBoxes .currency-numbers {
    font-size: 22px;
  }

  .apidata .revenue-boxes-wrap .chart-div {
    width: calc(33% - 10px);
    margin-right: 10px;
  }

  .apidata .revenue-boxes-wrap {
    min-width: 255px;
  }
}

@media screen and (max-width: 1300px) {
  .grid {
    margin: 0 20px 10px 0;
  }

  .apidata .revenue-boxes-wrap {
    width: calc((100% * 1/2) - 10px);
  }
}

@media screen and (max-width: 500px) {
  .apidata .input-column {
    max-width: unset;
  }

  .apidata .table-column .table-content {
    width: calc(var(--vw, 1vw) * 100 - 20px);
    overflow: auto;
  }

  .apidata .table-column .table-content .table-component td,
  .apidata .table-column .table-content .table-component th {
    min-width: 75px;
    font-size: 12px;
  }

  .apidata .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 10px) !important;
  }

  .apidata .payout-requests .table-component table {
    min-width: 600px;
  }
}

@media screen and (max-width: 991px) {
  .apidata .dashboard-row {
    flex-direction: column !important;
  }

  .apiData .table-column,
  .apiData .balance {
    width: 100% !important;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .apidata .fullwidth-button {
    width: 100%;
  }

  .apidata .defaultBtn {
    width: 100%;
  }
}
