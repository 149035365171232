.my-account .panel-block {
  width: 100%;
  padding: 15px 15px 0 15px;
}

.my-account .input-column {
  width: 200px;
  min-width: 130px;
  max-width: unset;
  margin: 10px;
  position: relative;
}

/* .my-account . */

.my-account .input-column input {
  padding-right: 30px;
}

.my-account .input-column input[disabled],
.my-account .input-column input[disabled] + .percent-sign {
  color: #979797;
}

.my-account .change-password-title {
  font-weight: bold;
  font-size: 16px;
}

.my-account .error-message {
  font-size: 14px;
  color: red;
}

.my-account .error-field {
  border: 2px solid red;
}
