.psps .add-deposit-button {
  background-color: #25282A;
  color: #FFFFFF;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.psps .add-payout-button {
  background-color: #25282A;
  color: #FFFFFF;
  font-size: 12px;
}

.psps .add-deposit-button.active {
  color: #FFFFFF;
}

.psps .add-payout-button.active {
  color: #FFFFFF;
}

.psps .add-deposit-button:hover {
  background-color: #4B5861;
  color: #FFFFFF;
}

.psps .add-payout-button:hover {
  background-color: #4B5861;
  color: #FFFFFF;
}

.psps .burger-container {
  cursor: pointer;
}

.psps .burger-container .burger-bar1,
.psps .burger-container .burger-bar2,
.psps .burger-container .burger-bar3 {
  width: 30px;
  height: 3px;
  background-color: #25282A;
  margin: 6px 0;
  transition: 0.4s;
}

.psps .burger-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 200px;
  top: 120px;
  right: 15px;
  border: 2px solid #CCCCCC;
  background-color: #FFFFFF;
  border-radius: 10px;
}

.psps .burger-content .link-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.psps .burger-content .link-item {
  display: block;
  width: 100%;
  margin: 5px 0 5px 0;
  padding: 5px 5px 5px 15px;
  color: #25282A;
}

.psps .burger-content .link-item:hover {
  color: #FFFFFF;
  background-color: #25282A;
}

.psps .change .burger-bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 3px);
  transform: rotate(-45deg) translate(-9px, 3px);
}

.psps .change .burger-bar2 {
  opacity: 0;
}

.psps .change .burger-bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -5px);
  transform: rotate(45deg) translate(-8px, -5px);
}

.psps .error-field {
  border: 1px solid #FF0000 !important;
  border-radius: 7px;
}

.psps .settings-table-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.psps .settings-table {
  width: 100%;
  margin-top: 15px;
  table-layout: fixed;
}

.psps .settings-table thead tr {
  background-color: #25282A;
  width: 100%;
  color: #FFFFFF;
}

.psps .settings-table th {
  padding: 8px;
  vertical-align: middle;
}

.psps .settings-table th:nth-child(1) {
  border-radius: 6px 0 0 6px;
}

.psps .settings-table th:nth-last-child(1) {
  border-radius: 0 6px 6px 0;
}

.psps .settings-table td {
  word-wrap: break-word;
  vertical-align: middle;
}

.psps .settings-table .table-input {
  width: 80%;
  outline: 0;
  text-align: right;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
}

.psps .settings-table .td-error {
  border: 2px solid #FF0000;
}

@media screen and (max-width: 650px) {
  .psps .add-deposit-button,
  .psps .add-payout-button {
    display: none;
  }
}

@media screen and (min-width: 650px) {
  .psps .burger-container {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .psps .settings-table thead > tr {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
  }

  .psps .settings-table tbody {
    display: block;
  }

  .psps .settings-table tr td:first-child,
  .psps .settings-table tr th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }

  .psps .settings-table {
    width: auto;
  }

  .psps .settings-table th,
  .psps .settings-table td {
    min-width: 125px;
  }
}

@media screen and (max-width: 1900px) {
  .psps .settings-table th,
  .psps .settings-table td {
    width: 125px;
  }
}

@media screen and (min-width: 501px) and (max-width: 1200px) {
  .psps .settings-table td:nth-child(1),
  .psps .settings-table th:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 3;
    background-color: inherit;
  }
}

@media screen and (min-width: 701px) and (max-width: 991px) {
  .psps .settings-table-wrapper {
    width: calc(var(--vw, 1vw) * 100);
  }
}

@media screen and (min-width: 501px) and (max-width: 700px) {
  .psps .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 20px) !important;
  }

  .psps .settings-table-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 20px);
  }
}

@media screen and (min-width: 992px) and (max-width: 1900px) {
  .psps .settings-table-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 185px);
  }
}

@media screen and (min-width: 501px) and (max-width: 991px) {
  .psps .report-content {
    width: calc(var(--vw, 1vw) * 100);
  }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
  .psps .report-content {
    width: calc(var(--vw, 1vw) * 100 - 190px);
  }
}

@media screen and (min-width: 1101px) and (max-width: 1300px) {
  .psps .report-content {
    width: calc(var(--vw, 1vw) * 100 - 180px);
  }
}

@media screen and (min-width: 1301px) and (max-width: 1500px) {
  .psps .report-content {
    width: calc(var(--vw, 1vw) * 100 - 150px);
  }
}

@media screen and (min-width: 1501px) and (max-width: 1650px) {
  .psps .report-content {
    width: calc(var(--vw, 1vw) * 100 - 130px);
  }
}

@media screen and (min-width: 1651px) and (max-width: 1800px) {
  .psps .report-content {
    width: calc(var(--vw, 1vw) * 100 - 115px);
  }
}

@media screen and (min-width: 1651px) and (max-width: 1920px) {
  .psps .report-content {
    width: calc(var(--vw, 1vw) * 100 - 100px);
  }
}
