.payouts .header {
  width: 100%;
  background-color: #F9F9F9;
  height: 70px;
  margin-top: 60px;
}

.payouts .input-column {
  width: 270px;
  margin: 10px;
  position: relative;
}

.payouts .input-column input {
  padding-right: 30px;
}

.payouts .input-column input[disabled],
.payouts .input-column input[disabled] + .percent-sign {
  color: #979797;
}

.info-icon {
  margin-right: 3px;
}

.payouts .payouts-wrapper {
  width: 100%;
  overflow: auto;
  position: relative;
}

.payouts table {
  width: 100%;
  margin-top: 15px;
}

.payouts table thead tr {
  background-color: #25282A;
  width: 100%;
  color: #FFFFFF;
}

.payouts table th {
  padding: 8px;
  vertical-align: middle !important;
}

.payouts table td {
  vertical-align: middle !important;
  word-wrap: break-word;
}

.payouts table th:nth-child(1) {
  border-radius: 6px 0 0 6px;
}

.payouts table th:nth-last-child(1) {
  border-radius: 0 6px 6px 0;
}

.payouts table .btn-approve {
  height: 35px;
  width: 100px;
  background-color: #F19C49;
  border-radius: 5px;
  display: block;
  margin: auto;
  color: #FFFFFF;
  padding: 7px;
}

.payouts table .btn-approve:hover {
  background-color: #25282A;
}

.payouts table .btn-deny:hover {
  background-color: #25282A;
  color: #FFFFFF;
}

.payouts table .btn-deny {
  height: 35px;
  width: 70px;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  padding: 7px;
  color: #000000;
}

.payouts .calendar-svg {
  width: 20px;
  height: 20px;
  fill: #C0C0C0;
  position: absolute;
  top: 37px;
  left: 10px;
  z-index: 1;
}

.payouts .datetime-container .form-control {
  padding-left: 35px !important;
}

.payouts .error-field {
  border: 2px solid #FF0000;
  border-radius: 7px;
}

.payouts .import-row {
  margin-bottom: 15px;
}

.payouts .import-row .arrow-column {
  position: relative;
  top: -15px;
  margin: 0 20px;
}

@media screen and (max-width: 500px) {
  .payouts .payouts-wrapper table thead > tr {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
  }

  .payouts .payouts-wrapper table {
    width: auto;
  }

  .payouts .payouts-wrapper tbody {
    display: block;
  }

  .payouts .payouts-wrapper table tr td:first-child,
  .payouts .payouts-wrapper table tr th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }
}

@media screen and (max-width: 800px) {
  .payouts .payouts-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 30px);
  }

  .payouts table td,
  .payouts table th {
    min-width: 125px;
  }

  .payouts table td:nth-child(1),
  .payouts table th:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: -2px;
    z-index: 3;
    background-color: inherit;
  }
}
