.bankCodes .add-button {
  background-color: #25282A;
  color: #FFFFFF;
  font-size: 12px;
}

.bankCodes .add-button:hover {
  background-color: #4B5861;
  color: #FFFFFF;
}

.bankCodes .provider-accounts {
  overflow: visible;
}

.bankCodes .checkbox-column {
  width: unset;
}

.bankCodes .actions {
  margin-top: 25px;
}

.bankCodes label.file-upload {
  display: flex;
}

.bankCodes .file-upload .upload {
  background: #25282A;
  color: #FFFFFF;
  padding: 11px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
}

.bankCodes .file-upload .upload:hover {
  background: #4B5861;
}
