.panel-block {
  width: 100%;
  padding: 15px 15px 0 15px;
}

.editClient .input-column {
  width: 270px;
  max-width: unset;
  position: relative;
}

.editClient .input-column input {
  padding-right: 30px;
}

.editClient .input-column input[disabled],
.editClient .input-column input[disabled] + .percent-sign {
  color: #979797;
}

.editClient .input-column .percent-sign {
  position: absolute;
  top: 36px;
  right: 10px;
}

.editClient .input-column .percent-sign-complex {
  position: absolute;
  top: 13px;
  right: 10px;
}

.button-block {
  margin-bottom: 25px;
}

.button-block .btn {
  padding-left: 75px;
  padding-right: 75px;
}

.faq-link {
  float: right;
  text-decoration: underline;
}

.info-icon {
  margin-right: 3px;
}

.editClient .react-switch-bg .answer.YES {
  color: #FFFFFF;
}

.client-informations .input-column {
  margin: 8px;
}

.client-informations .input-column .custom-select {
  margin-right: 8px;
}

.editClient .checkbox-container {
  margin: 10px;
}

.editClient .error-field {
  border: 2px solid red;
}

.editClient .error-message {
  font-size: 14px;
  color: red;
}

.editClient .general-table {
  overflow-x: auto;
  table-layout: fixed;
  min-width: 600px;
}

.editClient .general-table tr th {
  border: 0;
}

.editClient .general-table .td-toggler {
  width: 60px;
  border: 0;
}

.editClient .general-table .td-element {
  position: relative;
  border: 1px solid #DDDDDD;
  padding-right: 25px;
  min-width: 150px;
}

.editClient .general-table .td-error {
  border: 2px solid #FF0000;
}

.editClient .general-table .td-disabled {
  position: relative;
  background-color: #F1F1F1;
}

.editClient .general-table .table-input {
  width: 100%;
  border: 0;
  outline: 0;
  text-align: right;
}

.editClient .general-table .percent-sign {
  position: absolute;
  top: 8px;
  right: 10px;
}

.editClient .mids-table-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.editClient .mids-table-wrapper p {
  position: sticky;
  left: 0;
}

.editClient .mids-table {
  width: 100%;
  margin-top: 15px;
  table-layout: fixed;
}

.editClient .mids-table thead tr {
  background-color: #25282A;
  width: 100%;
  color: #FFFFFF;
}

.editClient .mids-table th {
  padding: 8px;
  vertical-align: middle;
}

.editClient .mids-table th:nth-child(1) {
  border-radius: 6px 0 0 6px;
}

.editClient .mids-table th:nth-last-child(1) {
  border-radius: 0 6px 6px 0;
}

.editClient .mids-table td {
  word-wrap: break-word;
  vertical-align: middle;
}

.editClient .mids-table .view-mid {
  height: 35px;
  width: 70px;
  background-color: #F19C49;
  border-radius: 5px;
  display: block;
  color: #FFFFFF;
  margin: auto;
  padding: 7px;
}

.editClient .mids-table .view-mid:hover {
  background-color: #25282A;
}

.editClient .mids-table .edit-mid {
  height: 35px;
  width: 70px;
  background-color: #FFFFFF;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  padding: 7px;
  color: #000000;
  display: block;
  margin: auto;
}

.editClient .mids-table .edit-mid:hover {
  background-color: #25282A;
  color: #FFFFFF;
}

.editClient .mids-table .table-input {
  width: 80%;
  outline: 0;
  text-align: right;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #CCCCCC;
}

.editClient .mids-table .td-error {
  border: 2px solid #FF0000;
}

.editClient input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
}

.editClient input[type="checkbox"]:checked + label::after {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.editClient input[type="checkbox"]:checked + label[disabled]::after {
  opacity: 0.5;
}

.editClient .styled-input-single {
  position: relative;
  margin: 10px;
}

.editClient .styled-input-single label {
  cursor: pointer;
  margin-left: 35px;
}

.editClient .styled-input-single label[disabled] {
  cursor: initial;
}

.editClient .styled-input-single label::before,
label::after {
  content: '';
  position: absolute;
  top: 50%;
  border-radius: 50%;
}

.editClient .styled-input-single label::before {
  left: 0;
  width: 25px;
  height: 25px;
  margin: -15px 0 0;
  background: #FFFFFF;
  box-shadow: 0 0 1px grey;
}

.editClient .styled-input-single label::after {
  left: 5px;
  width: 15px;
  height: 15px;
  margin: -10px 0 0;
  opacity: 0;
  background: #25282A;
  transform: translate3d(-40px, 0, 0) scale(0.5);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
}

.editClient .styled-input--square label::before,
label::after {
  border-radius: 0;
}

.editClient .provider-accounts .actions {
  flex-direction: row !important;
  margin-left: 15px;
}

.editClient .provider-accounts button:not(.defaultBtn) {
  border-radius: 50%;
  box-shadow: none;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  color: #FFFFFF;
  margin: 0 5px;
}

.editClient .provider-accounts button.delete {
  background-color: #CCCCCC;
}

.editClient .provider-accounts button.add {
  width: 30px;
  position: relative;
  font-size: 18px;
  background-color: #25282A;
}

.editClient .provider-accounts button.add .plus {
  position: relative;
  top: -2px;
}

.editClient .provider-accounts .loading-wrapper {
  width: 60px;
}

.editClient .provider-accounts .loading-wrapper img {
  height: 25px;
}

@media screen and (max-width: 1296px) {
  .editClient .provider-accounts .account-row {
    padding: 10px 0 15px;
    border-bottom: 1px solid #CCCCCC;
  }
}

@media all and (min-width: 0) and (max-width: 959px) {
  .button-block {
    width: 100%;
  }

  .editClient .btn.defaultBtn {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .editClient .input-column {
    width: 100%;
  }

  .editClient .select-input-column {
    width: 100%;
    margin-right: 15px;
  }

  .editClient .select-input-row {
    flex-direction: column !important;
  }

  .editClient .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 5px) !important;
  }
}

/* @media screen and (max-width: 500px) {
  .editClient .mids-table thead > tr {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
  }

  .editClient .mids-table tbody {
    display: block;
  }

  .editClient .mids-table tr td:first-child,
  .editClient .mids-table tr th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 40;
  }

  .editClient .mids-table tr th:first-child,
  .editClient .mids-table tr td:first-child {
    background-color: inherit;
  }

  .editClient .mids-table {
    width: auto;
  }

  .editClient .mids-table th,
  .editClient .mids-table td {
    min-width: 125px;
  } */

/* } */

@media screen {
  .editClient .mids-table th,
  .editClient .mids-table td {
    width: 125px;
  }
}

@media screen and (min-width: 501px) and (max-width: 3200px) {
  .editClient .mids-table td:nth-child(1),
  .editClient .mids-table th:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 3;
    background-color: inherit;
  }
}

@media screen and (min-width: 1900px) {
  .settings-table-wrapper {
    min-width: 2699px;
  }
}

@media screen and (min-width: 701px) and (max-width: 991px) {
  .editClient .mids-table-wrapper {
    width: calc(var(--vw, 1vw) * 100);
  }
}

@media screen and (min-width: 501px) and (max-width: 700px) {
  .editClient .panel {
    max-width: calc(var(--vw, 1vw) * 100 + 20px) !important;
  }

  .editClient .mids-table-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 20px);
  }
}

@media screen and (min-width: 992px) and (max-width: 3200px) {
  .editClient .mids-table-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 185px);
  }
}

.mids-table-wrapper .input-column .Select-menu-outer {
  top: auto;
  bottom: 100%;

  /* z-index: 99999; */
}

.spanTiers .Select-menu-outer {
  top: auto;
  bottom: 100%;
}

.mids-table-wrapper .add,
.mids-table-wrapper .delete {
  width: 30px;
  margin: 6px;
  outline: 0;
  padding: 0;
  border-top: 1px solid #DDDDDD;
}

.mids-table-wrapper .add {
  position: relative;
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  height: 30px;
  background-color: #25282A;
  color: #FFFFFF;
  padding-top: 5px !important;
}

.mids-table-wrapper .add .plus {
  position: absolute;
  top: 1px;
  left: 10px;
}

.mids-table-wrapper .delete {
  border-radius: 50%;
  box-shadow: 0;
  border: 0;
  font-size: 12px;
  font-weight: bold;
  height: 30px;
  background-color: #CCCCCC;
  color: #FFFFFF;
}

.tiersInput {
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 8px;
}

.manual-data-input .manual-data-input-column {
  padding-left: 0 !important;
}

.manual-data-input .manual-data-input-body .settings-table-wrapper {
  margin-top: -30px !important;
  margin-left: 20px;
}

.spanTiers {
  padding: 8px;
  border-top: 1px solid #DDDDDD;
}

.tiersInputAmount {
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 8px;
}

.tiersInputAmount input:invalid {
  border: 2px solid  #FF0000;
}

.emptyTh {
  max-width: 75px;
}

.changedInputTiers {
  padding: 8px;
  border-top: 1px solid #DDDDDD;
}

.changedInputTiers input:invalid {
  border: none;
  outline: 2px solid red !important;
  border-radius: 5px;
}

.buttonsTd {
  border-top: 1px solid #DDDDDD;
  max-width: 75px;
}

.hiddenButtonStyle {
  min-width: 90.47px;
}
