.clients .panel-block {
  width: 100%;
  padding: 15px 15px 0 15px;
}

.clients .input-column {
  width: 270px;
  margin-right: 3%;
  position: relative;
}

.clients .input-column input {
  padding-right: 30px;
}

.clients .input-column input[disabled],
.clients .input-column input[disabled] + .percent-sign {
  color: #979797;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.button-block {
  margin-bottom: 25px;
}

.button-block .btn {
  padding-left: 75px;
  padding-right: 75px;
}

.info-icon {
  margin-right: 3px;
}

.clients .clients-wrapper {
  width: 100%;
  overflow: auto;
  position: relative;
}

.clients table {
  width: 100%;
  margin-top: 15px;
}

.clients table thead tr {
  background-color: #25282A;
  width: 100%;
  color: #FFFFFF;
}

.clients table th {
  padding: 8px;
}

.clients table th:nth-child(1) {
  border-radius: 6px 0 0 6px;
}

.clients table th:nth-last-child(1) {
  border-radius: 0 6px 6px 0;
}

.clients table td {
  word-wrap: break-word;
  vertical-align: middle;
}

.clients table .view-client {
  height: 35px;
  width: 70px;
  background-color: #F19C49;
  border-radius: 5px;
  display: block;
  color: #FFFFFF;
  margin: auto;
  padding: 7px;
}

.clients table .view-client:hover {
  background-color: #25282A;
}

.clients table .edit-client {
  height: 35px;
  width: 70px;
  background-color: #FFFFFF;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  padding: 7px;
  color: #000000;
  display: block;
  margin: auto;
}

.clients table .edit-client:hover {
  background-color: #25282A;
  color: #FFFFFF;
}

.clients .search-bar {
  position: relative;
}

.clients .search-suggests {
  position: absolute;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  background-color: #FFFFFF;
  z-index: 10;
  top: 42px;
  width: 100%;
}

.clients .search-suggests .suggestion {
  padding: 8px;
  cursor: pointer;
}

.clients .search-suggests .suggestion:hover {
  background-color: #25282A;
  color: #FFFFFF;
}

.clients .add-merchant-button {
  background-color: #25282A;
  color: #FFFFFF;
  margin-right: 10px;
  font-size: 12px;
}

.clients .add-merchant-button.active {
  color: #FFFFFF;
}

.clients .add-merchant-button:hover {
  background-color: #4B5861;
  color: #FFFFFF;
}

@media screen and (max-width: 500px) {
  .clients .clients-wrapper table thead > tr {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    top: 0;
    z-index: 50;
    display: block;
  }

  .clients .clients-wrapper table {
    width: auto;
  }

  .clients .clients-wrapper tbody {
    display: block;
  }

  .clients .clients-wrapper table tr td:first-child,
  .clients .clients-wrapper table tr th:first-child {
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 40;
    background-color: inherit;
  }
}

@media screen and (max-width: 600px) {
  .clients .clients-wrapper {
    width: calc(var(--vw, 1vw) * 100 - 30px);
  }

  .clients table td,
  .clients table th {
    min-width: 125px;
  }
}

@media screen and (min-width: 501px) and (max-width: 600px) {
  .clients table td:nth-child(1),
  .clients table th:nth-child(1) {
    position: sticky;
    position: -webkit-sticky;
    left: -2px;
    z-index: 3;
    background-color: inherit;
  }
}
